import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Categories, SearchBar, QuestionsList } from '../components/Faqs';
import './faq.scss';

export const FrequentlyAskedQuestions = props => {
  const { location, data } = props;
  const { page } = data;
  const { data: pageData } = page;

  const { questions, page_name: pageTitle, meta_title: metaTitle, meta_description: metaDescription } = pageData;

  const [visibleQuestions, setVisibleQuestions] = useState(questions || []);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    queryQuestions(activeCategory, searchQuery);
  }, [activeCategory, searchQuery]);

  const setSearchHandler = event => {
    const query = event.target.value.toLowerCase() || '';
    setSearchQuery(query);
  };

  const setCategoryHandler = (event, category) => {
    if (event) event.preventDefault();
    setActiveCategory(category);
  };

  const queryQuestions = (category, query) => {
    const filteredQuestions = questions.filter(faqItem => {
      const queryMatches = query ? faqItem.question.text.toLowerCase().includes(query) : true;
      const categoryMatch = category ? category === faqItem.category : true;
      return queryMatches && categoryMatch;
    });
    setVisibleQuestions(filteredQuestions);
  };

  const searchFocusHandler = (event, clicked) => {
    event.preventDefault();
    setSearchActive(clicked);
  };

  // SEO
  const seoData = {
    title: metaTitle ? metaTitle.text : `${pageTitle} | Safedome`,
    desc: metaDescription ? metaDescription.text : null,
  };

  const categoriesArr = questions.map(faqItem => faqItem.category);
  const categories = [...new Set(categoriesArr)];

  const faqSchemaData = questions.map(questionItem => {
    const { question, answer } = questionItem;
    return {
      '@type': 'Question',
      name: question.text,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer.text,
      },
    };
  });

  console.log('location.pathname', location.pathname);

  const hideEscapeRoute = location.pathname === '/faqs-app';

  return (
    <Layout location={location} hideEscapeRoutes={hideEscapeRoute}>
      <SEO {...seoData} />
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(faqSchemaData)}
        </script>
      </Helmet>
      <div className="wrapper faq-wrapper">
        <div className="faq-header">
          <h1 className="faq-header-title">{pageTitle.text}</h1>
          <SearchBar
            searchActive={searchActive}
            searchQuery={searchQuery}
            setSearchHandler={setSearchHandler}
            searchFocusHandler={searchFocusHandler}
          />
        </div>
        <Categories activeCategory={activeCategory} categories={categories} setCategoryHandler={setCategoryHandler} />
        <QuestionsList questions={visibleQuestions} location={location} />
      </div>
    </Layout>
  );
};

export default FrequentlyAskedQuestions;

export const pageQuery = graphql`
  query FrequentlyAskedQuestionsQuery {
    page: prismicFrequentlyAskedQuestions {
      uid
      data {
        page_name {
          text
        }
        questions {
          question {
            text
          }
          answer {
            text
            html
          }
          category
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`;
