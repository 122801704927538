import React, { useState } from 'react';
import queryString from 'query-string';
import Collapse from 'react-css-collapse';
import { Icon } from 'components';
import { copyToClipboard } from 'helpers';
import { CopyLinkButton } from '.';
import './QuestionsListItem.scss';

const toKebabCase = str =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');

const QuestionsListItem = props => {
  const { question, answer, location } = props;
  const questionId = toKebabCase(question.text);
  const queryParams = queryString.parse(location.search);
  const anchoredQuestionId = queryParams.question;

  const [questionExpanded, setQuestionExpanded] = useState(questionId === anchoredQuestionId);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const copyAnchorLink = (event, id) => {
    event.preventDefault();
    if (!showCopyNotification) {
      const { origin, pathname } = location;
      const anchorUrl = `${origin}${pathname}?question=${id}`;
      copyToClipboard(anchorUrl);
      setShowCopyNotification(true);
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 1500);
    }
  };

  return (
    <div id={questionId} className={`faq-question ${questionExpanded ? 'expanded' : ''}`}>
      <div className="question-title">
        <CopyLinkButton
          question={question}
          questionId={questionId}
          showCopyNotification={showCopyNotification}
          copyAnchorLink={copyAnchorLink}
        />
        <button
          type="button"
          className="question-toggle"
          onClick={() => setQuestionExpanded(!questionExpanded)}
          aria-label={`See answer to "${question.text}"`}
        >
          {question.text}
          <Icon className="faq-question-icon" title="ChevronDown" />
        </button>
      </div>
      <div className="question-content">
        <Collapse isOpen={questionExpanded}>
          <div className="question-text-container" dangerouslySetInnerHTML={{ __html: answer && answer.html }} />
        </Collapse>
      </div>
    </div>
  );
};

export default QuestionsListItem;
