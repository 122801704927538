import React from 'react';
import './Categories.scss';

const CategoryButtons = props => {
  const { activeCategory, categories = [], setCategoryHandler } = props;

  return (
    <section className="category-buttons">
      <div className="buttons">
        <button
          type="button"
          className={`button category-button ${!activeCategory ? 'active' : ''}`}
          onClick={event => setCategoryHandler(event, null)}
          aria-label="Show questions from all categories"
        >
          All
        </button>
        {categories.map(category => (
          <button
            key={category}
            type="button"
            className={`button category-button selection-button ${activeCategory === category ? 'active' : ''}`}
            onClick={event => setCategoryHandler(event, activeCategory === category ? null : category)}
            aria-label={`Filter by ${category} questions`}
          >
            {category}
          </button>
        ))}
      </div>
    </section>
  );
};

export default CategoryButtons;
