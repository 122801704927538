import React, { useEffect } from 'react';
import queryString from 'query-string';
import { QuestionsListItem } from '.';
import './QuestionsList.scss';

const scrollToPageElement = (event, elementId) => {
  if (event) event.preventDefault();
  const targetEl = document.getElementById(elementId);
  if (targetEl) targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const QuestionsList = props => {
  const { location, questions } = props;

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const anchoredQuestionId = queryParams.question;
    if (anchoredQuestionId) scrollToPageElement(null, anchoredQuestionId);
  }, []);

  return (
    <section className="faq-question-list">
      <QuestionListBody questions={questions} location={location} />
    </section>
  );
};

const QuestionListBody = ({ questions, location }) => {
  if (!questions || questions.length < 1) {
    return (
      <div className="faq-questions">
        <span className="no-results-message">Sorry we couldn't find anything that meets that criteria</span>
      </div>
    );
  }

  return (
    <div className="faq-questions">
      {questions.map(faq => (
        <QuestionsListItem key={faq.question.text} {...faq} location={location} />
      ))}
    </div>
  );
};

export default QuestionsList;
